import { IDeploymentList } from "../../shared/models/deploy-job-management.model";
import { IAuditLog, IAuditLogs, IGetAuditLogsResponse } from "../../shared/models/audit-log.model";
import { IGetDJAuditLogsResponse } from "../../shared/models/audit-log.model";
import * as _ from 'lodash';
import { DateTime } from 'luxon';

export function loadInitiateState() {
  const response: IDeploymentList = {
    deployments: []
  };
  return response;
}

export function loadInitiateStateConfig() {
  const response: IGetAuditLogsResponse = {
    trails:
    {
      items: [],
      totalItems: 0
    }
  };
  return response;
}

export function loadInitiateAuditLogStateConfig() {
  const response: IGetDJAuditLogsResponse = {
    trails:
    {
      items: [],
      totalItems: 0
    }
  };
  return response;
}

export function checkForOldAudits(auditLogs: IAuditLogs, thresholdInDays: number): boolean {
  const currentDate = DateTime.now();
  return _.some(auditLogs.items, (t: IAuditLog) => {
    let parsedDate = getFormattedDate(t.date);
    parsedDate = parsedDate.isValid ? parsedDate : currentDate;
    return currentDate.diff(parsedDate, 'days').days > thresholdInDays;  // number of days after which popup is to be displayed
  }
  );
}

function getFormattedDate(date: string, format: string = 'MM-dd-yyyy') {
  const parsedDate = DateTime.fromFormat(date.substring(0, 10), format);
  return parsedDate;
}