import { Injectable } from "@angular/core";
import { Action, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { Effect, ofType, Actions } from "@ngrx/effects";
import { DealerInfoService } from "../../shared/services/dealer-info.service";
import * as fromActions from "./deploy-job-management.actions";
import { MatSnackBar } from "@angular/material/snack-bar";
import { checkForOldAudits, loadInitiateAuditLogStateConfig, loadInitiateState, loadInitiateStateConfig } from "./deploy-job-management.adapter";
import { DeployJobManageState } from "./deploy-job-management.reducer";
import { IDeploymentList } from "../../shared/models/deploy-job-management.model";
import { DeployJobManageService } from "../../shared/services/deploy-job-manage.service";
import { IGetAuditLogsResponse, IGetDJAuditLogsResponse } from "../../shared/models/audit-log.model";
import { CacheService } from "../../shared/services/cache.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "../../shared/components/confirm-dialog/confirm-dialog.component";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { DateTime } from 'luxon';

@Injectable()
export class DeployJobManagementEffects {
  errMsgSWW: string = "Something Went Wrong, Please try again later";
  dismissMsg: string = "Dismiss";
  successSnackbar: string = "success-snackbar";

  constructor(
    private readonly actions$: Actions,
    private deployJobManageService: DeployJobManageService,
    private snackBar: MatSnackBar,
    private readonly deployJobManageState: Store<DeployJobManageState>,
    private readonly cacheService: CacheService,
    private readonly dialog: MatDialog,
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly dealerInfoService: DealerInfoService
  ) { }

  @Effect()
  loadDeployJobManage: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.LoadDeployJobManage>(
      fromActions.DeployJobManageActionTypes.LOAD_DJ_MANAGEMENT
    ),
    mergeMap((action: fromActions.LoadDeployJobManage) =>
      this.deployJobManageService
        .getDeploymentJobManage(action.payload.dealerCode)
        .pipe(
          map((deployJob: IDeploymentList) => {
            return new fromActions.LoadDeployJobManageSuccess({
              deployJob,
            });
          }),
          catchError((err) => {
            if (err.status === 404) {
              this.dealerInfoService._isDealerConfigNotFound = true;
              const deployJob = loadInitiateState();
              this.deployJobManageState.dispatch(
                new fromActions.LoadDeployJobManageFailure({
                  error: err.error.message,
                  deployJob,
                })
              );
            } else {
              this.snackBar.open(
                err?.error?.message || this.errMsgSWW,
                this.dismissMsg,
                { duration: 5000 }
              );
            }
            const deployJob = loadInitiateState();
            return of(
              new fromActions.LoadDeployJobManageFailure({
                error: err,
                deployJob,
              })
            );
          })
        )
    )
  );

  @Effect()
  loadDeployJobConfig: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.LoadDeployJobConfig>(
      fromActions.DeployJobManageActionTypes.LOAD_DJ_CONFIGURATION
    ),
    mergeMap((action: fromActions.LoadDeployJobConfig) =>
      this.deployJobManageService
        .getDeploymentJobConfig(action.payload.dealerCode)
        .pipe(
          map((deployJobConfig: IGetAuditLogsResponse) => {
            const oldChangesPopupDisplayed = this.cacheService.getValue('oldChangesPopupDisplayed');
            if (!oldChangesPopupDisplayed &&
              checkForOldAudits(
                deployJobConfig.trails, this.deployJobManageService.oldChangesPopupThreshold
              )) {
              const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                width: '500px',
                data: {
                  popupTitle: this.translateService.instant(
                    'LOCALIZATION.DEPLOYMENT_JOB.OLD_CHANGES_POPUP_TITLE'
                  ),
                  popupBodyText: this.translateService.instant(
                    'LOCALIZATION.DEPLOYMENT_JOB.OLD_CHANGES_POPUP_BODY',
                    { days: this.deployJobManageService.oldChangesPopupThreshold }
                  ),
                  cancelButtonText: this.translateService.instant(
                    'LOCALIZATION.COMMON.OK'
                  ),
                  confirmButtonText: this.translateService.instant(
                    'LOCALIZATION.DEPLOYMENT_JOB.REVIEW_CHANGES'
                  )
                },
              });
              const afterClosedSub = dialogRef.afterClosed().subscribe((result) => {
                if (result?.action === "confirm") {
                  this.router.navigate(["pe-admin/deploy-job-management"]);
                }
                afterClosedSub.unsubscribe();
              });
              this.cacheService.set('oldChangesPopupDisplayed', true, 3600000);
            }
            return new fromActions.LoadDeployJobConfigSuccess({
              deployJobConfig,
            });
          }),
          catchError((err) => {
            if (err.status === 404) {
              this.dealerInfoService._isDealerConfigNotFound = true;
              const deployJobConfig = loadInitiateStateConfig();
              this.deployJobManageState.dispatch(
                new fromActions.LoadDeployJobConfigFailure({
                  error: err.error.message,
                  deployJobConfig,
                })
              );
            } else {
              this.snackBar.open(
                err?.error?.message || this.errMsgSWW,
                this.dismissMsg,
                { duration: 5000 }
              );
            }
            const deployJobConfig = loadInitiateStateConfig();
            return of(
              new fromActions.LoadDeployJobConfigFailure({
                error: err,
                deployJobConfig,
              })
            );
          })
        )
    )
  );

  @Effect()
  loadAuditLog: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.LoadAuditLog>(
      fromActions.DeployJobManageActionTypes.LOAD_DJ_AUDITLOG
    ),
    mergeMap((action: fromActions.LoadAuditLog) =>
      this.deployJobManageService
        .getAuditLogs(action.payload.dealerCode, action.payload.deploymentId)
        .pipe(
          map((auditLogs: IGetDJAuditLogsResponse) => {
            return new fromActions.LoadAuditLogSuccess({
              auditLogs,
            });
          }),
          catchError((err) => {
            if (err.status === 404) {
              this.dealerInfoService._isDealerConfigNotFound = true;
              const auditLogs = loadInitiateAuditLogStateConfig();
              this.deployJobManageState.dispatch(
                new fromActions.LoadAuditLogFailure({
                  error: err.error.message,
                  auditLogs,
                })
              );
            } else {
              this.snackBar.open(
                err?.error?.message || this.errMsgSWW,
                this.dismissMsg,
                { duration: 5000 }
              );
            }
            const auditLogs = loadInitiateAuditLogStateConfig();
            return of(
              new fromActions.LoadAuditLogFailure({
                error: err,
                auditLogs,
              })
            );
          })
        )

    )
  );
}
