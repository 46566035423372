import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  private cache = new Map<string, any>();

  getValue(key: string): any {
    return this.cache.get(key);
  }
  get(key: string): Observable<any> {
    return new BehaviorSubject(this.cache.get(key));
  }

  set(key: string, value: any, expirationTime?: number) {
    this.cache.set(key, value);
    if (expirationTime) {
      setTimeout(() => {
        this.cache.delete(key);
      }, expirationTime);
    }
  }
}